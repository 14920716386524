import React, {useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import { useLocation } from "@reach/router";

//assets
import useWindowSize from "../../../hooks/useWindowSize";
import {getButtonCustomStyles, getFontSize, downloadMedia} from "../../../assets/js";
import * as images from "../../../assets/images/icons";

//components
import { useConsent } from "../../../components/ConsentContext";
import YouTubeModal from "../../../components/YouTubeModal";

//constants
import {COLOR} from "../../../constants/colors";

// data-layer
import { setLearnMoreClickedEvent, setDownloadClickedEvent } from "../../../assets/js/data-layer";
import CustomLink from "../../../components/CustomLink";

export const ContentPanelCard = ({card, ind,allowTransition,isMobile,isVisible}) => {
  const location = useLocation()
  const consentAccepted = useConsent()
  const {width} = useWindowSize()

  const [show, setShow] = useState(false)
  const [modalDetails, setModalDetails] = useState('')
  const showSubTitle = card.show_subtitle
  const showParagraph = card.show_paragraph
  const showCTAButton = card.show_cta_button

  // console.log(card)
  const isYouTubeVideo = card.youtube_video
  const imageSrc = width < 768 ? card.banner?.thumbnails?.mobile.url : card.banner?.url
  const imageAlt = card.banner?.alt || ''
  const youTubeSrc = card.youtube_link || ''

  // Links
  const isDownloadLink = card.cta_download_button
  const isTargetBlank = card.cta_button_open_in_new_tab
  // const target = isTargetBlank ? '_blank' : '_self'

  const subTitle = card.subtitle?.richText || []
  const subTitleTextColor = card.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = card.subtitle_font_size_desktop || '35'
  const subTitleFontSizeMobile = card.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const paragraph = card.paragraph?.richText || []
  const paragraphTextColor = card.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = card.paragraph_font_size_desktop || '20'
  const paragraphFontSizeMobile = card.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  // CTA Button
  const ctaButtonText = card.cta_button_text?.text || ''
  const ctaDownloadButtonFileName = card.cta_download_file_name || ''

  // CTA Button Link
  const ctaButtonLink = card.cta_button_link?.url || ''
  const ctaButtonDownloadLink = card.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: card.cta_button_text_color,
    bg: card.cta_button_bg_color,
    bgT: card.cta_button_bg_color_transparency,
    border: card.cta_button_border_color,
    colorHover: card.cta_button_text_color_hover,
    bgHover: card.cta_button_bg_color_hover,
    bgHoverT: card.cta_button_bg_color_hover_transparency,
    borderHover: card.cta_button_border_color_hover,
    borderRadius: card.cta_button_border_radius || "0",
    padding: width < 768 ?  "5px 12px" : null
  }

  const {ctaButtonStyle} = getButtonCustomStyles(buttonColorSet)

  const onPlayBtnClick = (data) => {
    setShow(true)
    setModalDetails(data)
  }

  const onCloseBtnClick = () => {
    setShow(false)
    setModalDetails('')
  }

  const onCTABtnClick = (url, referrer, name, consent) =>{
    const dataLayer = { url, referrer, name: name.toLowerCase()}
    setLearnMoreClickedEvent(dataLayer, consent)
  }

  return <div key={`card-${ind}`} className= {`col-md-4 col-sm-12 content-panel__card ${allowTransition?(isMobile?'content-transition-right':'fade-animation-loop-enable'):''} ${isVisible && allowTransition ? (isMobile?'content-animation-right':''):''}`}>
      {showCTAButton &&
          <div className='content-panel__card-actions'>
            {!isDownloadLink && <CustomLink to={ctaButtonLink} title={ctaButtonText} className={"content-panel__card-action"}
                                            onClick={()=> onCTABtnClick(ctaButtonLink, location.pathname,  ctaButtonText, consentAccepted)}
                                            children={ctaButtonText}
                                            isNewWindow={isTargetBlank}
            />}

            {isDownloadLink &&
                <button title={ctaButtonText} className='content-panel__card-action'
                        onClick={() => {
                          downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                          // 'download_clicked' data layer event
                          setDownloadClickedEvent({
                            fileName: ctaDownloadButtonFileName,
                            clickUrl: ctaButtonDownloadLink,
                            clickText: ctaButtonText,
                            pageUrl: location.href
                          }, consentAccepted)
                        }} >
                        {ctaButtonText}
                </button> }
          </div>
      }
      <div className='position-relative content-panel__image-wrap'>
        {isYouTubeVideo && <div className="content-panel__item-play-btn">
          <img src={images.playVideoBtnWebp} alt="Play Button" className="play-btn"
               onClick={() => onPlayBtnClick(youTubeSrc)}/>
        </div>}
        <img src={imageSrc} alt={imageAlt} className='content-panel__image'/>
      </div>

      <div className='content-panel__content-wrap'>
        {showSubTitle && <h4 className='content-panel__subtitle'>
          {subTitle.map((row) => {
            return <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>
          })
          }
        </h4>}

        {showParagraph && width >= 768 && <p className='content-panel__paragraph'>
          {paragraph.map((row, index) => {
            return <span key={index} style={paragraphStyles}>{row.text}<br/></span>
          })
          }
        </p>}

        {showCTAButton && width < 768 &&
            <div title={ctaButtonText} className="content-panel__button-mobile" style={ctaButtonStyle}>
              {ctaButtonText}
            </div>
        }

        {showCTAButton && width >= 768 &&
              <div title={ctaButtonText} className="content-panel__cta-button" style={ctaButtonStyle} >
                {ctaButtonText}
                {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
                {!isTargetBlank && isDownloadLink && <FontAwesomeIcon icon={faDownload} className='ml-2'/>}
              </div>
        }

      </div>
      {show && modalDetails && <YouTubeModal modalDetails={modalDetails} closeModal={onCloseBtnClick}/>}

  </div>
}