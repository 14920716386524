// core
import React, { useState } from 'react'
import { Link } from 'gatsby'

// components
import BatchImage from './BatchImage'
import DecipherBatchNumber from './DecipherBatchNumber'

const HiddenFields = (HiddenFields) => {
    // batch image modal functions
    const [showBatchImage, setShowBatchImage] = useState(false)
    const closeBatchImage = () => setShowBatchImage(false)
    const openBatchImage = (e) => {
        e.preventDefault()
        setShowBatchImage(true)
    }

    // decipter batch number modal functions
    const [showDecipherBatchNumber, setShowDecipherBatchNumber] = useState(false)
    const closeDecipherBatchNumber = () => setShowDecipherBatchNumber(false)
    const openDecipherBatchNumber = (e) => {
        e.preventDefault()
        setShowDecipherBatchNumber(true)
    }

    return (
    <>
        <div className="row">
            <div className="col-12">
                <div className="row">

                    <div className={"hidden-block form-section-info " + (HiddenFields.inquiryTypeID === '100000006' ? '' : 'toggle-hide')}>
                        <div className="col-12">
                            <p>
                            For all rebate related questions and concerns, Please contact Inmar directly at <Link to={`mailto:InmarRebateCenter@inmar.com?subject=Rebate`} target="_blank" title="InmarRebateCenter@inmar.com">InmarRebateCenter@inmar.com</Link>.
                            </p>

                            <p>
                            To check on the status of your rebate, please visit: <Link to={`https://www.inmarrebates.com`} target="_blank" title="www.inmarrebates.com">www.inmarrebates.com</Link>.
                            </p>
                            <p>
                            Due to strict regulations surrounding alcohol, we're not legally able to send coupons from our corporate office. You can find coupons on <Link to={`https://home.ibotta.com/`} target="_blank" title="Ibotta">Ibotta</Link>, a free coupon app that you can download on your Apple or Android device.
                            </p>
                        </div>
                    </div>

                    <div className={"hidden-block form-section-info " + (HiddenFields.inquiryTypeID === '100000012' ? '' : 'toggle-hide')}>
                        <div className="col-12">
                            <p>
                            Use our <Link to={`https://www.whiteclaw.com/locator`} target="_blank" title="Product locator">product locator</Link> for help to find your favorite flavour!
                            </p>
                        </div>
                    </div>

                    <div className={"hidden-block form-section-info " + (HiddenFields.inquiryTypeID === '100000002' ? '' : 'toggle-hide')}>
                        <div className="col-12">
                            <p>
                            Please be sure to check out our <Link to={`/faq`} target="_blank" title="FAQ">FAQ</Link> section for more information on ingredients and nutrition.
                            </p>
                        </div>
                    </div>

                    <div className={"hidden-block form-section-info " + (HiddenFields.inquiryTypeID === '100000005' ? '' : 'toggle-hide')}>
                        <div className="col-12">
                            <h3>Thank you for considering us for your sponsorship request.</h3>
                            <p>Due to the large volume of sponsorship related inquiries, only chosen requests will be contacted.</p>
                        </div>
                    </div>

                    <div className={"hidden-block form-section-info " + (HiddenFields.inquiryTypeID === '100000007' ? '' : 'toggle-hide')}>
                        <div className="col-12">
                            <h3>Thank you for considering us for a partnership/media opportunity.</h3>
                            <p>Due to the large volume of partnership/media related inquiries, only chosen requests will be contacted.</p>
                        </div>
                    </div>

                    <div className={"hidden-block form-section-info " + (HiddenFields.inquiryTypeID === '100000008' ? '' : 'toggle-hide')}>
                        <div className="col-12 form-btn-container">
                            <p>
                            You can determine the freshness of our products by reading the batch number, which can be found under any can. <a href="#" onClick={openBatchImage}>VIEW IMAGE</a>.
                            </p>
                            <p>
                            Once you have found the batch number, you will be able to derive the production date. Our products should be consumed within one year of the production date. From there, carbonation, sweetness and robustness may start to diminish.
                            </p>
                            <button className="form-btn form-btn-long-text" data-toggle="modal" onClick={openDecipherBatchNumber}>How to decipher batch number</button>
                        </div>
                        {/* Batch Image Component */}
                        <BatchImage showBatchImage={showBatchImage} closeBatchImage={closeBatchImage} />
                        <DecipherBatchNumber showDecipherBatchNumber={showDecipherBatchNumber} closeDecipherBatchNumber={closeDecipherBatchNumber} />
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default HiddenFields