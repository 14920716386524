// core
import React, {useEffect,useState } from 'react';
import { graphql } from 'gatsby';

// assets
import useWindowSize from '../../hooks/useWindowSize';
// constants
import { COLOR } from '../../constants/colors';

import { AnchorLink } from '../../components/AnchorLink';

export const TickerTape = ({ slice }) => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [items,setItems] = useState(slice.items || [])
  const sectionId = slice.primary.section_id?.text || slice.id;
  const anchorLink = slice.primary.anchor_link?.text || '';

  const contentType = slice.primary.item_type;
  const showBackgroundImage = slice.primary.show_background_image;
  const backgroundColor = slice.primary.background_color || COLOR.WHITE;
  const backgroundImage = slice.primary.background_image?.url;
  const showBorder = slice.primary.select_border
  const borderColor = slice.primary.border_color;
  const topPadding = slice.primary.top_padding || '0';
  const bottomPadding = slice.primary.bottom_padding || '0';

  const FontTag = slice.primary.font_style || 'h1';
  const textColor = slice.primary.text_color || COLOR.BLACK
  const fontSizeDesktop = slice.primary.font_size_desktop || null;
  const fontSizeMobile = slice.primary.font_size_mobile || null;
  const fontSize = isMobile?(fontSizeMobile?fontSizeMobile+'px':''):(fontSizeDesktop?fontSizeDesktop+'px':'');

  const imageHeightDesktop = slice.primary.image_height_desktop || '100';
  const imageHeightMobile = slice.primary.image_height_mobile || '70';
  const tickerSpeed = slice.primary.ticker_speed ||300;
  const repeatArray = (arr) => {
  
      let repeatedArray = [];
      for (let i = 0; i < 16; i++) {
        repeatedArray = [...repeatedArray, ...arr];
      }
      return repeatedArray;
    
  };
  useEffect(() => {
    let newItems = []
    if (items.length <16) {
      newItems = repeatArray(items)
      setItems(newItems)
    }
  }, []);
  
  const sectionStyles = {
    background: showBackgroundImage ? `url('${backgroundImage}')` : backgroundColor,
    borderStyle: showBorder?'solid':'',
    borderColor: showBorder?borderColor:'',
    borderLeft:'0px',
    borderRight:'0px',
    paddingTop:topPadding + "px",
    paddingBottom:bottomPadding + "px",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat'
    
  };
  const fontStyles = {
    fontSize: fontSize,
    fontFamily:"BentonSans Bold",
    color:textColor
  };
 
  return (
      <section className="ticker-wrap" style={sectionStyles}>
      {anchorLink && <AnchorLink link={anchorLink} />}
        <div className="ticker" style={{ '--ticker-animation-duration': `${tickerSpeed}s` }}>
            <span className="item-collection-1" style={{ '--ticker-animation-duration': `${tickerSpeed}s` }}>
            {items.length>0 && items.map((item, index) => (
          <>
            {contentType?.toLowerCase() === 'text' ? (
              <span key = {`item-collection ${index}`}className="item" style={fontStyles}>{item.ticker_text}</span>
            ) : (
              <img  className="item" key = {`item-collection ${index}`} src={item.ticker_image?.url} alt="Ticker Item" style={{ height: isMobile ?( imageHeightMobile + 'px') : (imageHeightDesktop +'px')}} />
            )}
         </>
            ))}
            </span>
            <span className="item-collection-2">
            {items.length>0 && items.map((item, index) => (
             <>
              {contentType?.toLowerCase() === 'text' ? (
              <span className="item" key = {`item-collection-repeat ${index}`} style={fontStyles}>{item.ticker_text}</span>
            ) : (
              <img className="item" key = {`item-collection-repeat ${index}`} src={item.ticker_image?.url} alt="Ticker Item" style={{ height: isMobile ?( imageHeightMobile + 'px') : (imageHeightDesktop +'px')}} />
            )}
         </>
            ))}
            </span>
        </div>
      </section>

  );
};

export const fragment = graphql`
  fragment PageDataBodyTickerTape on PrismicGenericPageDataBodyTickerTape {
    id
    items {
      ticker_image {
        alt
        url
      }
      ticker_text
    }
    slice_type
    primary {
      background_color
      background_image {
        alt
        url
      }
      border_color
      border_radius
      font_size_desktop
      font_size_mobile
      image_height_desktop
      image_height_mobile
      item_type
      show_background_image
      ticker_speed
      font_style
      top_padding
      text_color
      bottom_padding
    }
  }
`;
