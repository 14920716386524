// core
import React, {useRef, useEffect} from 'react'
import {PrismicRichText} from "@prismicio/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import { useLocation } from "@reach/router";

// assets
import useWindowSize from '../../../hooks/useWindowSize'
import {downloadMedia, getButtonCustomStyles, getFontSize} from "../../../assets/js";
import { useConsent } from "../../../components/ConsentContext";

// constants
import {COLOR} from "../../../constants/colors";

// data-layer
import { setDownloadClickedEvent } from "../../../assets/js/data-layer";

export const ProductTastingNoteItem = ({item, index, reverse}) => {
  // console.log('ProductTastingNoteItem', item)
  const location = useLocation()
  const consentAccepted = useConsent()
  const contentReverse = reverse ? 'reverse': ''

  const {width} = useWindowSize()
  const playerRef = useRef()

  // Toggles
  const showTitle = item.show_title
  const showSubTitle = item.show_subtitle
  const showParagraph = item.show_paragraph
  const showCTAButton = item.show_cta_button

  const productType = item.product_type
  const contentTypeImage = item.content_type

  // Links
  const isDownloadLink = item.cta_download_button
  const isTargetBlank = item.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const title = item.title?.richText || []
  const titleTextColor = item.title_text_color || COLOR.BLACK
  const titleTextSizeDesktop = item.title_font_size_desktop || '47'
  const titleTextSizeMobile = item.title_font_size_mobile || '24'
  const titleTextFontSize = getFontSize(width, titleTextSizeDesktop, titleTextSizeMobile)


  const subTitleAroma = item.subtitle_aroma?.richText || []
  const subTitleTaste = item.subtitle_taste?.richText || []
  const subTitleFinish = item.subtitle_finish?.richText || []
  const subTitleTextColor = item.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = item.subtitle_font_size_desktop || '30'
  const subTitleFontSizeMobile = item.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)


  const paragraphAroma = item.paragraph_aroma?.richText || []
  const paragraphTaste = item.paragraph_taste?.richText || []
  const paragraphFinish = item.paragraph_finish?.richText || []
  const paragraphTextColor = item.paragraph_text_color || COLOR.BLACK
  const paragraphTextSizeDesktop = item.paragraph_font_size_desktop || '18'
  const paragraphTextSizeMobile = item.paragraph_font_size_mobile || '16'
  const paragraphTextSizeSize = getFontSize(width, paragraphTextSizeDesktop, paragraphTextSizeMobile)

  // CTA Button
  const ctaButtonText = item.cta_button_text?.text || ''
  const ctaDownloadButtonFileName = item.cta_download_file_name || ''

  // CTA Button Link
  const ctaButtonLink = item.cta_button_link?.url || ''
  const ctaButtonDownloadLink = item.cta_download_button_file?.url || ''

  // styles
  const sectionStyles = {
    backgroundColor: item.section_bg_color || COLOR.WHITE,
  }

  const titleStyles = {
    color: titleTextColor, fontSize: titleTextFontSize
  }

  const subTitleStyles = {
    color: subTitleTextColor, fontSize: subTitleFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor, fontSize: paragraphTextSizeSize
  }

  // CTA Styles
  const buttonColorSet = {
    color: item.cta_button_text_color,
    bg: item.cta_button_bg_color,
    bgT: item.cta_button_bg_color_transparency,
    border: item.cta_button_border_color,
    colorHover: item.cta_button_text_color_hover,
    bgHover: item.cta_button_bg_color_hover,
    bgHoverT: item.cta_button_bg_color_hover_transparency,
    borderHover: item.cta_button_border_color_hover,
    borderRadius: item.cta_button_border_radius || "0",
  }

  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)

  // CTA Styles
  const ctaMobButtonStyle = {
    color: item.cta_mobile_button_text_color || COLOR.WHITE,
    backgroundColor: item.cta_mobile_button_bg_color || COLOR.BLACK,
  }

  // image
  const image = {
    src: width < 768 ? item.banner?.thumbnails?.mobile.url : item.banner?.url,
    alt: item.banner?.alt || ''
  }

  // product
  const product = {
    src: width < 768 ? item.product_image?.thumbnails?.mobile.url : item.product_image?.url,
    alt: item.product_image?.alt || ''
  }

  // video
  const githubVideoFilename = width < 768 ? item?.github_video_filename_mobile : item?.github_video_filename_desktop;
  const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
  const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

  useEffect(() => {
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  }, [])

  return (<div className={`tasting-notes__wrapper ${contentReverse}`} key={index}>
    <div className='tasting-notes__column tasting-notes__column--content'>
      <div className={'tasting-notes__content-wrapper'}>
        {showTitle && <h3 className='tasting-notes__title'>
          {title.map((row) => {
            return <span key={row.text} style={titleStyles}>
            <PrismicRichText field={[row]}/><br/>
          </span>
          })}
        </h3>}

        {showSubTitle && <h4 className='tasting-notes__subtitle'>
          {subTitleAroma.map((row) => {
            return <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>
          })}
        </h4>}

        {showParagraph && <p className='tasting-notes__paragraph'>
          {paragraphAroma.map((row) => {
            return <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>
          })}
        </p>}

        {showSubTitle && <h4 className='tasting-notes__subtitle'>
          {subTitleTaste.map((row) => {
            return <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>
          })}
        </h4>}

        {showParagraph && <p className='tasting-notes__paragraph'>
          {paragraphTaste.map((row) => {
            return <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>
          })}
        </p>}

        {showSubTitle && <h4 className='tasting-notes__subtitle'>
          {subTitleFinish.map((row) => {
            return <span key={row.text} style={subTitleStyles}>{row.text}<br/></span>
          })}
        </h4>}

        {showParagraph && <p className='tasting-notes__paragraph'>
          {paragraphFinish.map((row) => {
            return <span key={row.text} style={paragraphStyles}>{row.text}<br/></span>
          })}
        </p>}


        {showCTAButton && width >=768 && <>
          { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className="tasting-notes__cta-button" style={ctaButtonStyle} target={target}>
            { ctaButtonText }
            { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
          </a> }

          { isDownloadLink && <button title={ctaButtonText} className="tasting-notes__cta-button" style={ctaButtonStyle}
                                      onClick={()=> {
                                        downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                        // 'download_clicked' data layer event
                                        setDownloadClickedEvent({
                                          fileName: ctaDownloadButtonFileName,
                                          clickUrl: ctaButtonDownloadLink,
                                          clickText: ctaButtonText,
                                          pageUrl: location.href
                                        }, consentAccepted)
                                      }}
          >
            { ctaButtonText }
            <FontAwesomeIcon icon={faDownload} className='ml-2'/>
          </button> }
        </>
        }

      </div>
      {width < 768 && <div>
        <img src={product.src} alt={product.alt} className={`tasting-notes__product-img ${productType}`}/>
      </div>}
    </div>

    <div className='tasting-notes__column tasting-notes__column--media'>
      {contentTypeImage && <img src={image.src} alt={image.alt} className={`tasting-notes__img ${contentReverse}`}/>}
      {!contentTypeImage &&
          <video className={`tasting-notes__video ${contentReverse}`} playsInline autoPlay muted loop width='100%' height='100%'
                 ref={playerRef} src={video}/>}

      {width >= 768 && <img src={product.src} alt={product.alt} className={`tasting-notes__product-img ${productType}`}/>}
    </div>
    {showCTAButton && width < 768 &&
        <>
          { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className="tasting-notes__cta-button mobile" style={ctaMobButtonStyle} target={target}>
            { ctaButtonText }
            { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
          </a> }

          { isDownloadLink && <button title={ctaButtonText} className="tasting-notes__cta-button mobile" style={ctaMobButtonStyle}
                                      onClick={()=> {
                                        downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                        // 'download_clicked' data layer event
                                        setDownloadClickedEvent({
                                          fileName: ctaDownloadButtonFileName,
                                          clickUrl: ctaButtonDownloadLink,
                                          clickText: ctaButtonText,
                                          pageUrl: location.href
                                        }, consentAccepted)
                                      }}
          >
            { ctaButtonText }
            <FontAwesomeIcon icon={faDownload} className='ml-2'/>
          </button> }
        </>
    }
  </div>);

}