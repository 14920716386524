
import { Link } from '@reach/router';
import React,{useState,useEffect, useRef} from 'react';

const TileCard = ({ image, title, subtitle, link, hoverColor,minHeight,cardMinHeight,linkText }) => {
    const [isHovered, setIsHovered] = useState(false);   
    const titleRef = useRef(null);
    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };
  const cardStyle = {
    backgroundImage: `url(${image?.url})`,
    minHeight:cardMinHeight
  }
  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: isHovered ? 0: 0.7,
    transition: 'opacity 0.3s ease-in-out',
  };
  const contentStyle = {
    minHeight: `${minHeight}px`, // Set min-height based on the height of the title
  };
  return (
    <>
    {link.link_type === "Document"?  
    <Link to ={linkText?`${link.url}#${linkText}`:link.url}  target={link.target?link.target:'_self'}>
    <div className={`tile-card`}
         style={cardStyle}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}>
      <div className="tile-overlay" style={overlayStyle}></div>
      <div className="tile-content" style={contentStyle}>
        <h3  ref={titleRef}>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </div>
    </div>
    </Link>
    :
    <a href={link.url} target={link.target?link.target:'_self'}>
    <div className={`tile-card`}
         style={cardStyle}
         onMouseEnter={handleMouseEnter}
         onMouseLeave={handleMouseLeave}>
      <div className="tile-overlay" style={overlayStyle}></div>
      <div className="tile-content" style={contentStyle}>
        <h3  ref={titleRef}>{title}</h3>
        {subtitle && <p>{subtitle}</p>}
      </div>
    </div>
    </a>    
    }
    </>
    
  );
};

export default TileCard;
