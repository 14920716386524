// core
import React, { useRef, useEffect } from 'react'
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { useLocation } from "@reach/router";
// assets
import useWindowSize from '../../hooks/useWindowSize'
import { downloadMedia, getButtonCustomStyles, getFontSize } from "../../assets/js";
import { useConsent } from "../../components/ConsentContext";

// components
import CustomLink from "../../components/CustomLink";

// constants
import { COLOR } from "../../constants/colors";
import { AnchorLink } from "../../components/AnchorLink";

// data-layer
import { setDownloadClickedEvent } from "../../assets/js/data-layer";

export const AboutProduct = ({slice}) => {
  // console.log('SplitBanner', slice)
  const location = useLocation()
  const consentAccepted = useConsent()

  const {width} = useWindowSize()
  const playerRef = useRef()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // links
  const isDownloadLink = slice.primary.cta_download_button
  const isTargetBlank = slice.primary.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Toggles
  const showTitle = slice.primary.show_title
  const showCTAButton = slice.primary.show_cta_button

  const contentReverse = slice.primary.reverse_content ? 'reverse' : ''
  const contentTypeImage = slice.primary.content_type

  // Text
  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextSizeDesktop = slice.primary.title_font_size_desktop || '52'
  const titleTextSizeMobile = slice.primary.title_font_size_mobile || '34'
  const titleTextFontSize = getFontSize(width, titleTextSizeDesktop, titleTextSizeMobile)

  const paragraphs = slice.items || []

  // CTA Button
  const ctaButtonText = slice.primary.cta_button_text?.text || ''
  const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''

  // CTA Button Link
  const ctaButtonLink = slice.primary.cta_button_link?.url || ''
  const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''

  // styles
  const sectionStyles = {
    "--bg-color": slice.primary.section_bg_color || COLOR.WHITE,
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize,
    "--bg-color": slice.primary.section_bg_color || COLOR.WHITE,
  }

  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
    borderRadius: slice.primary.cta_button_border_radius || "0",
  }

  const {ctaButtonStyle} = getButtonCustomStyles(buttonColorSet)

  // CTA Styles
  const ctaMobButtonStyle = {
    color: slice.primary.cta_mobile_button_text_color || COLOR.WHITE,
    backgroundColor: slice.primary.cta_mobile_button_bg_color || COLOR.BLACK,
  }

  // image
  const image = {
    src: width < 768 ? slice.primary.banner?.thumbnails?.mobile.url : slice.primary.banner?.url,
    alt: slice.primary.banner?.alt || ''
  }

  // video
  const githubVideoFilename = width < 768 ? slice.primary?.github_video_filename_mobile : slice.primary?.github_video_filename_desktop;
  const githubVideoFilenameTrimmed = githubVideoFilename?.trim() || ''
  const video = githubVideoFilenameTrimmed ? `/videos/${githubVideoFilenameTrimmed}` : ''

  useEffect(() => {
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current?.play()
    });

  }, [])

  return (<section className="about-product" style={sectionStyles} id={`about-product-${sectionId}`}>
    { anchorLink && <AnchorLink link={anchorLink}/> }

    <div className={`about-product__wrapper ${contentReverse}`}>
      <div className='about-product__column about-product__column--content'>
        <div className={`about-product__content-wrapper ${contentReverse}`}>

          {showTitle && <h3 className='about-product__title mobile' style={sectionStyles}>
            {title.map((row) => {
              return <span key={row.text} style={titleStyles}>
                  <PrismicRichText field={[row]}/><br/>
                </span>
            })
            }
          </h3>}

          <p className={`about-product__paragraph  ${contentReverse}`}>
            {paragraphs.map((row,index) => {
              const paragraphTextColor = row.paragraph_text_color || COLOR.BLACK
              const paragraphTextSizeDesktop = row.paragraph_font_size_desktop || '20'
              const paragraphTextSizeMobile = row.paragraph_font_size_mobile || '23'
              const paragraphTextSizeSize = getFontSize(width, paragraphTextSizeDesktop, paragraphTextSizeMobile)

              const paragraphStyles = {
                color: paragraphTextColor,
                fontSize: paragraphTextSizeSize
              }

              return <span key={row.paragraph.text+index} style={paragraphStyles}>{row.paragraph.text}<br/></span>
            })
            }
          </p>

        </div>
      </div>

      <div className='about-product__column about-product__column--media'>
        <div className='about-product__sticky-container'>
          <div className={`about-product__mask ${contentReverse}`}>
            {showTitle && <h3 className={`about-product__title ${contentReverse}`} style={sectionStyles}>
              {title.map((row) => {
                return <span key={row.text} style={titleStyles}>
                  <PrismicRichText field={[row]}/><br/>
                </span>
              })
              }
            </h3>}
            { showCTAButton && <div className={`w-100 pb-5 about-product__cta-button-wrap ${contentReverse}`} style={sectionStyles}>

              { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className="about-product__cta-button"
                                      style={ctaButtonStyle} target={target}>
                { ctaButtonText }
                { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
              </a> }

              { isDownloadLink && <button title={ctaButtonText} className="about-product__cta-button" style={ctaButtonStyle}
                                          onClick={()=>{
                                            downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                            // 'download_clicked' data layer event
                                            setDownloadClickedEvent({
                                              fileName: ctaDownloadButtonFileName,
                                              clickUrl: ctaButtonDownloadLink,
                                              clickText: ctaButtonText,
                                              pageUrl: location.href
                                            }, consentAccepted)
                                          }}
              >
                { ctaButtonText }
                <FontAwesomeIcon icon={faDownload} className='ml-2'/>
              </button> }
            </div> }
          </div>
          { contentTypeImage  && <img src={image.src} alt={image.alt} className='about-product__img'/> }

          {!contentTypeImage &&
              <video className='about-product__video' playsInline autoPlay muted loop width='100%' height='100%'
                     ref={playerRef} src={video}/>}
        </div>
      </div>
    </div>
    {showCTAButton &&
        <>
          { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className="about-product__cta-button mobile"
                                  style={ctaMobButtonStyle} target={target}>
            { ctaButtonText }
            { isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
          </a> }

          { isDownloadLink && <button title={ctaButtonText} className="about-product__cta-button mobile" style={ctaMobButtonStyle}
                                      onClick={()=> {
                                        downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                        // 'download_clicked' data layer event
                                        setDownloadClickedEvent({
                                          fileName: ctaDownloadButtonFileName,
                                          clickUrl: ctaButtonDownloadLink,
                                          clickText: ctaButtonText,
                                          pageUrl: location.href
                                        }, consentAccepted)
                                      }}
          >
            { ctaButtonText }
            <FontAwesomeIcon icon={faDownload} className='ml-2'/>
          </button> }
        </>
    }
  </section>);
}

export const fragment = graphql`
  fragment PageDataBodyAboutProduct on PrismicGenericPageDataBodyAboutProduct  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      banner {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      cta_button_link {
        url
      }
      github_video_filename_mobile
      github_video_filename_desktop
      reverse_content
      content_type
      show_cta_button
      show_title
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_mobile_button_bg_color
      cta_mobile_button_text_color
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
      section_bg_color
    }
    items {
       paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
    }
  }`