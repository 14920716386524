import React,{useRef} from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from "@prismicio/react";

// assets
import { downloadMedia } from '../../assets/js/downloadMedia';
import { getButtonCustomStyles } from '../../assets/js/getButtonCustomStyles';
import { getFontSize } from "../../assets/js/getFontSize";
import useWindowSize from "../../hooks/useWindowSize";

// constants
import { COLOR } from "../../constants/colors";
import {AnchorLink} from "../../components/AnchorLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpRightFromSquare} from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import {faDownload} from "@fortawesome/free-solid-svg-icons/faDownload";
import useVisibility from '../../utils/visibilityHook';


export const FullWidthTextWithButton = ({ slice }) => {
  const { width } = useWindowSize()
  const componentRef = useRef(null);
  const isVisible = useVisibility(componentRef);
  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  // Toggles
  const showSubTitle = slice.primary.show_subtitle
  const showTitle = slice.primary.show_title
  const showParagraph = slice.primary.show_paragraph
  const showCTAButton = slice.primary.show_cta_button
  const showAnimatedImage = slice.primary.show_animated_image
  const allowTransition = slice.primary.allow_transition

  // Links
  const isDownloadLink = slice.primary.cta_download_button
  const isTargetBlank = slice.primary.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  // Text
  const subTitle = slice.primary.subtitle?.richText || []
  const subTitleTextColor =slice.primary.subtitle_text_color || COLOR.BLACK
  const subTitleFontSizeDesktop = slice.primary.subtitle_font_size_desktop || '38'
  const subTitleFontSizeMobile = slice.primary.subtitle_font_size_mobile || '20'
  const subTitleFontSize = getFontSize(width, subTitleFontSizeDesktop, subTitleFontSizeMobile)

  const title = slice.primary.title?.richText || []
  const titleTextColor = slice.primary.title_text_color || COLOR.BLACK
  const titleTextFontSizeDesktop = slice.primary.title_font_size_desktop || '66'
  const titleTextFontSizeMobile = slice.primary.title_font_size_mobile || '40'
  const titleTextFontSize = getFontSize(width, titleTextFontSizeDesktop, titleTextFontSizeMobile)

  const paragraph = slice.primary.paragraph?.richText || []
  const paragraphTextColor = slice.primary.paragraph_text_color || COLOR.BLACK
  const paragraphFontSizeDesktop = slice.primary.paragraph_font_size_desktop || '24'
  const paragraphFontSizeMobile = slice.primary.paragraph_font_size_mobile || '20'
  const paragraphFontSize = getFontSize(width, paragraphFontSizeDesktop, paragraphFontSizeMobile)

  const subTitleStyles = {
    color: subTitleTextColor,
    fontSize: subTitleFontSize
  }

  const titleStyles = {
    color: titleTextColor,
    fontSize: titleTextFontSize
  }

  const paragraphStyles = {
    color: paragraphTextColor,
    fontSize: paragraphFontSize
  }

  const animatedImage = {
    alt: slice.primary.animated_image?.alt || '',
    url: slice.primary.animated_image?.url || ''
  }

  // CTA Button
  const ctaButton = {
    text: slice.primary.cta_button_text?.text || '',
    url: slice.primary.cta_button_link?.url || ''
  }

  const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''
  const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
    borderRadius: slice.primary.cta_button_border_radius || "0"
  }


  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)

  // Section

  const section = {
    bgColor: slice.primary.section_bg_color || COLOR.WHITE,
    mobileBgImg: slice.primary.section_bg_image?.thumbnails?.mobile.url || '',
    desktopBgImg: slice.primary.section_bg_image?.url || '',
    spacingTop: slice.primary.section_spacing_top || '0',
    spacingBottom: slice.primary.section_spacing_bottom || '0',
    minHeightDesktop: slice.primary.section_bg_image_min_height_desktop,
    minHeightMobile: slice.primary.section_bg_image_min_height_mobile
  }

  const isMobile = width <= 768;
  const minHeightValue = isMobile ? section.minHeightMobile : section.minHeightDesktop;
  const defaultMinHeight = minHeightValue || '75vh';
  const minHeight = section.desktopBgImg ? defaultMinHeight : 'unset';

  const sectionStyles = {
    backgroundColor: section.bgColor,
    backgroundImage: section.desktopBgImg ? `${isMobile ? `url('${section.mobileBgImg}')` : `url('${section.desktopBgImg}')`}` : null,
    minHeight: minHeight,
    marginTop: `${section.spacingTop}px`,
    marginBottom: `${section.spacingBottom}px`
  }

  return (
    <section ref={componentRef} className="full-width-text full-width-text-padding" style={sectionStyles} id={`full-width-text-${sectionId}`}>
      { anchorLink && <AnchorLink link={anchorLink}/> }

      <div className='full-width-text__text-wrap'>

        { showAnimatedImage && <div className='full-width-text__animated-image'>
          <img src={animatedImage.url} alt={animatedImage.alt} width='100'/>
        </div>}

        { showSubTitle && <h4 className={`full-width-text__subtitle ${allowTransition?'fade-in-animation-enable':""} ${isVisible && allowTransition ?'fade-in-bottom-up':''}`}>
          { subTitle.map((row, index) => {
            return  <span key={index} style={subTitleStyles}>
              <PrismicRichText field={[row]}/><br/>
            </span>})
          }
        </h4> }

        { showTitle && <h3 className={`full-width-text__title ${allowTransition?'fade-in-animation-enable':""} ${isVisible && allowTransition ?'fade-in-bottom-up':''}`}>
          { title.map((row, index) => {
            return  <span key={index} style={titleStyles}>
              <PrismicRichText field={[row]}/><br/>
            </span>})
          }
        </h3> }

        { showParagraph && <p className='full-width-text__paragraph'>
          { paragraph.map((row, index) => {
            return  <span key={index} style={paragraphStyles}>{row.text}<br/></span>})
          }
        </p>}

        {showCTAButton && <>
          {!isDownloadLink &&
              <a href={ctaButton.url} title={ctaButton.text} className={`full-width-text__cta-button mt-4 ${allowTransition?'fade-in-animation-enable':""} ${isVisible && allowTransition ?'fade-in-bottom-up':''}`}
                 style={ctaButtonStyle} target={target}>
                {ctaButton.text}
                {isTargetBlank && <FontAwesomeIcon icon={faUpRightFromSquare} className='ml-2'/>}
              </a>}

          {isDownloadLink &&
              <button title={ctaButton.text} className={`full-width-text__cta-button mt-4 ${allowTransition?'fade-in-animation-enable':""} ${isVisible && allowTransition ?'fade-in-bottom-up':''}`} style={ctaButtonStyle}
                      onClick={() => downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)}
              >
                {ctaButton.text}
                <FontAwesomeIcon icon={faDownload} className='ml-2'/>
              </button>}
        </>}
      </div>
    </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyFullwidthTextWithButton on PrismicGenericPageDataBodyFullwidthTextWithButton  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      allow_transition
      cta_button_bg_color
      cta_button_bg_color_hover
      cta_button_border_color
      cta_button_border_color_hover
      cta_button_border_radius
      cta_button_link {
        url
      }
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_bg_color_transparency
      cta_button_bg_color_hover_transparency
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      paragraph {
        text
        richText
      }
      paragraph_text_color
      paragraph_font_size_desktop
      paragraph_font_size_mobile
      section_spacing_top
      section_spacing_bottom
      section_bg_color
      section_bg_image {
        thumbnails {
          mobile {
            alt
            url
          }
        }
        url(imgixParams: {q: 100, dpi: 300, dpr: 2, auto: "enhance"})
        alt
      }
      section_bg_image_min_height_desktop
      section_bg_image_min_height_mobile
      animated_image {
        url
        alt
      }
      show_animated_image
      show_cta_button
      show_paragraph
      show_title
      show_subtitle
      subtitle{
        text
        richText
      }
      subtitle_text_color
      subtitle_font_size_desktop
      subtitle_font_size_mobile
      title {
        text
        richText
      }
      title_text_color
      title_font_size_desktop
      title_font_size_mobile
    }
  }
`;