import React, { useEffect, useState, useRef } from 'react'
import { graphql } from 'gatsby'

const mapHtmlToJsAttributes = (attribute) => {
  const htmlToJsAttributes = {
    'allowfullscreen': 'allowFullScreen',
    'class': 'className',
    'for': 'htmlFor',
    'readonly': 'readOnly'
  };

  return htmlToJsAttributes[attribute.toLowerCase()] || attribute;
};

export const Iframe = ({ slice }) => {
  // console.log("iFrame Slice", slice)

  const { items } = slice
  const isBrowser = typeof window !== "undefined"
  const [iframeHeight, setIframeHeight] = useState('calc(100vh - 90px)');
  const iframeRef = useRef(null);

  const iframeAttributes = items.reduce((acc, item) => {
    const attributeName = mapHtmlToJsAttributes(item.attribute_name);
    acc[attributeName] = item.attribute_value !== null && item.attribute_value !== undefined ? item.attribute_value : (attributeName === 'allowFullScreen' ? true : "");
    return acc;
  }, {});
  const iframeOrigin = isBrowser ? iframeAttributes?.src? new URL(iframeAttributes?.src).origin:'' : '';

  useEffect(() => {
    if (!isBrowser) return;
    
    const handleMessage = (event) => {
      if (event.origin !== iframeOrigin) return;
      setIframeHeight(`${event.data.iframeContentHeight}px`);
    };

    const handleResize = () => {
      iframeRef.current.contentWindow.postMessage('sendHeight', iframeOrigin);
    };

    window.addEventListener('message', handleMessage);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('message', handleMessage);
      window.removeEventListener('resize', handleResize);
    };
  }, [iframeOrigin]);

  return (
    <iframe ref={iframeRef} style={{ height: iframeHeight, width: '100%', border: 'none', overflow: 'hidden' }} {...iframeAttributes} />
  )
}

export const fragment = graphql`
  fragment PageDataBodyIframe on PrismicGenericPageDataBodyIframe  {
    id
    items {
      attribute_name
      attribute_value
    }
  }
`;