import HeroBannerWithModal from "./HeroBannerWithModal";
import VideoGallery from "./VideoGallery";
import { YouTubeVideoSection } from "./YouTubeVideoSection"
import { FullWidthTextWithButton } from "./FullWidthTextWithButton";
import { SplitBanner } from "./SplitBanner";
import { ContentPanel } from "./ContentPanel";
import { ProductCarousel } from "./ProductCarousel";
import { Hero } from "./Hero";
import { TabbedRange } from "./TabbedRange";
import InstagramSection from "../instagram-section/InstagramSection";
import { AccordianComponent } from "./AccordianComponent"
import { FindNearYou } from "./FindNearYou";
import { AboutProduct } from "./AboutProduct";
import { ProductRange } from "./ProductRange";
import { PolaroidPhotoGallery } from "./PolaroidPhotoGallery";
import { TickerTape } from "./TickerTape";
import {TilesComponent} from './TilesComponent'
import { Iframe } from "./Iframe"
import { OvalImage } from './OvalImage'
import { MasonryGallery } from "./MasonryGallery"
import { FullWidthSlider } from './FullWidthSlider'
import { Event } from "./Events";
import { FullWidthSectionDivider } from "./FullWidthSectionDivider";
import { CrossLink } from "./CrossLink";
import { ProductTastingNotes } from "./ProductTastingNotes";
import { Membership } from "./Membership";
import { WyngForm } from "./WyngForm";
import { ClickMoreBanner } from "./ClickMoreBanner";

export const components = {
  hero: Hero,
  hero_banner_with_modal: HeroBannerWithModal,
  video_gallery: VideoGallery,
  youtube_video_section: YouTubeVideoSection,
  fullwidth_text_with_button: FullWidthTextWithButton,
  about_product: AboutProduct,
  product_range: ProductRange,
  polaroid_photo_gallery: PolaroidPhotoGallery,
  event: Event,
  _full_width_section_divider:FullWidthSectionDivider,
  crosslink: CrossLink,
  split_banner: SplitBanner,
  content_panel: ContentPanel,
  product_carousel: ProductCarousel,
  tabbed_range:TabbedRange,
  instagram_section: InstagramSection,
  accordion:AccordianComponent,
  find_near_you: FindNearYou,
  ticker_tape:TickerTape,
  tile:TilesComponent,
  iframe: Iframe,
  oval_image: OvalImage,
  masonry_gallery: MasonryGallery,
  fullwidth_slider: FullWidthSlider,
  product_tasting_notes: ProductTastingNotes,
  membership: Membership,
  wyng_form: WyngForm,
  click_more_banner: ClickMoreBanner
}