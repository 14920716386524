import { useState, useEffect, useMemo } from "react";

// Hook
export default function useOnScreen(ref) {

  const [isIntersecting, setIntersecting] = useState(false)

  const isBrowser = typeof window !== "undefined";

  useEffect(() => {
    if (!isBrowser) return;

    const observer = new IntersectionObserver(([entry]) => {setIntersecting(entry.isIntersecting)})

    if(!ref?.current) return;

    observer.observe(ref.current)
    return () => observer.disconnect()
  }, [])

  return isIntersecting
}