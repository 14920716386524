import React from 'react'
import {graphql} from 'gatsby'
import { getButtonCustomStyles, downloadMedia } from "../../assets/js";
import { COLOR } from "../../constants/colors";
import { AnchorLink } from "../../components/AnchorLink";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons/faUpRightFromSquare";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { useLocation } from "@reach/router";
import { useConsent } from "../../components/ConsentContext";

// data-layer
import { setDownloadClickedEvent } from "../../assets/js/data-layer";

export const CrossLink = ({ slice }) => {
  // console.log('CrossLink slice', slice)
  const location = useLocation()
  const consentAccepted = useConsent()

  const sectionId = slice.primary.section_id?.text || slice.id
  const anchorLink = slice.primary.anchor_link?.text || ''

  const sectionBGColor = slice.primary.section_bg_color
  const paddingTop = slice.primary.padding_top
  const paddingBottom = slice.primary.padding_bottom

  // Links
  const isDownloadLink = slice.primary.cta_download_button
  const isTargetBlank = slice.primary.cta_button_open_in_new_tab
  const target = isTargetBlank ? '_blank' : '_self'

  console.log("slice",slice);
  // CTA Button
  const ctaButtonText = slice.primary.cta_button_text?.text || ''
  const ctaDownloadButtonFileName = slice.primary.cta_download_file_name || ''

  // CTA Button Link
  const ctaButtonLink = slice.primary.cta_button_link?.url || ''
  const ctaButtonDownloadLink = slice.primary.cta_download_button_file?.url || ''

  // CTA Styles
  const buttonColorSet = {
    color: slice.primary.cta_button_text_color,
    bg: slice.primary.cta_button_bg_color,
    bgT: slice.primary.cta_button_bg_color_transparency,
    border: slice.primary.cta_button_border_color,
    colorHover: slice.primary.cta_button_text_color_hover,
    bgHover: slice.primary.cta_button_bg_color_hover,
    bgHoverT: slice.primary.cta_button_bg_color_hover_transparency,
    borderHover: slice.primary.cta_button_border_color_hover,
    borderRadius: slice.primary.cta_button_border_radius || "0",
  }

  const sectionStyles = {
    backgroundColor: sectionBGColor || COLOR.WHITE,
    paddingBottom: `${paddingBottom}px` || '0',
    paddingTop: `${paddingTop}px` || '0',
  }

  const { ctaButtonStyle } = getButtonCustomStyles(buttonColorSet)



  return (
      <section className='crosslink' style={sectionStyles} id={`cross-link-${sectionId}`}>
        { anchorLink && <AnchorLink link={anchorLink}/> }

        { !isDownloadLink && <a href={ctaButtonLink} title={ctaButtonText} className="crosslink__cta-button" style={ctaButtonStyle} target={target}>
          { ctaButtonText }
          { isTargetBlank && <FontAwesomeIcon icon={faDownload} className='ml-2'/>}
        </a> }

        { isDownloadLink && <button title={ctaButtonText} className="crosslink__cta-button" style={ctaButtonStyle}
                                    onClick={()=> {
                                      downloadMedia(ctaButtonDownloadLink, ctaDownloadButtonFileName)

                                      // 'download_clicked' data layer event
                                      setDownloadClickedEvent({
                                        fileName: ctaDownloadButtonFileName,
                                        clickUrl: ctaButtonDownloadLink,
                                        clickText: ctaButtonText,
                                        pageUrl: location.href
                                      }, consentAccepted)
                                    }}
        >
          { ctaButtonText }
          <FontAwesomeIcon icon={faDownload} className='ml-2'/>
        </button> }
      </section>
  )
}

export const fragment = graphql`
  fragment PageDataBodyCrosslink on PrismicGenericPageDataBodyCrosslink  {
    id
    primary {
      section_id{
        text
      }
      anchor_link{
        text
      }
      cta_button_link {
        url
      }
      cta_button_bg_color
      cta_button_bg_color_transparency
      cta_button_bg_color_hover
      cta_button_bg_color_hover_transparency
      cta_button_border_color
      cta_button_border_radius
      cta_button_border_color_hover
      cta_button_text {
        text
        richText
      }
      cta_button_text_color
      cta_button_text_color_hover
      cta_button_open_in_new_tab
      cta_download_button
      cta_download_button_file {
        url
      }
      cta_download_file_name
      section_bg_color
      padding_top
      padding_bottom
    }
  }`